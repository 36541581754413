import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { CovTestForm } from './CovTestForm';
import { CovTest, EMPTY_COV_TEST } from './covTest';
import { Spinner } from '../spinner/Spinner';
import { saveCovTest } from '../firebase/firebaseService';

export function CovTestDialog(props: { open: boolean, onClose: VoidFunction }) {
  const [valid, setValid] = useState(false);
  const [test, setTest] = useState<CovTest | undefined>(EMPTY_COV_TEST);
  const [image, setImage] = useState<File | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const save = async () => {
    if (test) {
      setLoading(true);
      await saveCovTest(test, image)
      setLoading(false);
      props.onClose();
    }
  }

  return (
      <Dialog
          fullWidth={true}
          open={props.open}
          onClose={props.onClose}>
        <DialogTitle>Neuer Test</DialogTitle>
        <DialogContent>
          {
            loading ?
                <Spinner/> :
                <CovTestForm onChange={(value, image) => {
                  setTest(value)
                  setImage(image)
                  setValid(!!value)
                }}/>
          }
        </DialogContent>
        <DialogActions>
          <Button color={'inherit'} onClick={props.onClose}>Abbrechen</Button>
          <Button disabled={!valid} onClick={save}>Speichern</Button>
        </DialogActions>
      </Dialog>
  )
}
