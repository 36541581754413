import { useCallback, useMemo, useState } from 'react';
import { Auth, GoogleAuthProvider, signInWithPopup, User, UserCredential } from 'firebase/auth';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from './firebase';

export interface KdUser extends User {
  imageUrl: string | object | undefined,
  admin: boolean;
  name: string;
  email: string
}

export type GoogleActionHook = [
  () => void,
      KdUser | undefined,
  boolean,
      boolean | undefined
];

// eslint-disable-next-line import/no-anonymous-default-export
export default (
    auth: Auth,
    extraScopes: Array<string> = []
): GoogleActionHook => {
  const [error, setError] = useState<any>();
  const [
    loggedInUser,
    setLoggedInUser,
  ] = useState<KdUser>();
  const [loading, setLoading] = useState<boolean>(false);

  const signInWithGoogle = useCallback(async () => {
    setLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      extraScopes.forEach((extraScope) => provider.addScope(extraScope));
      await signInWithPopup(auth, provider).then(async (user: UserCredential) => {
        const adminSnapshots = await getDocs(collection(firestore, 'admins'));
        const admins = adminSnapshots.docs.map(it => it.data().mail);
        await user.user.getIdTokenResult().then(result => {
          const admin = admins.includes(user.user.email);
          setLoggedInUser({
            imageUrl: result.claims.picture,
            admin: admin,
            name: user.user.displayName,
            email: user.user.email
          } as KdUser);
        })
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(true);
    }
  }, [auth, extraScopes])

  return useMemo<GoogleActionHook>(() => [
    signInWithGoogle,
    loggedInUser,
    loading,
    error,
  ], [error, loading, loggedInUser, signInWithGoogle]);
}
