import React, { useEffect, useState } from 'react';
import { Alert, Box, Fab, Grid, Snackbar, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CovDate, CovTest, dateToString, snapshotToCovTest } from '../covTest/covTest';
import { History } from '../history/History';
import { AttendanceList } from '../attendanceList/AttendanceList';
import { CovTestDialog } from '../covTest/CovTestDialog';
import { firestore } from '../firebase/firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { collection } from 'firebase/firestore';
import { Spinner } from '../spinner/Spinner';

export function Dashboard() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [tests, setTests] = useState<CovTest[]>([]);
  const [testsByDate, setTestsByDate] = useState<CovTest[]>([]);
  const [dates, setDates] = useState<CovDate[]>([]);
  const [selectedDate, setSelectedDate] = useState<CovDate | undefined>(undefined);
  const [openError, setOpenError] = useState(false);

  const [value, loading, error] = useCollectionData(
      collection(firestore, 'tests'),
      {idField: 'id'},
  );

  useEffect(() => {
    setOpenError(!!error)
  }, [error])

  const reduceByDate = (givenTests: CovTest[]): CovDate[] => {
    let map = new Map<string, CovDate>();
    givenTests.forEach(it => map.set(dateToString(it.date), it.date))

    return Array.from(map.values())
  }

  const sort = (a: CovTest, b: CovTest) => {
    const dateA = a.date;
    const dateB = b.date;

    if (dateB.year === dateA.year) {
      if (dateB.month === dateA.month) {
        return dateB.day - dateA.day
      } else {
        return dateB.month - dateA.month
      }
    } else {
      return dateB.year - dateA.year
    }
  }

  useEffect(() => {
    if (value) {
      const covTests = value.map(it => snapshotToCovTest(it)).sort(sort);
      if (covTests && covTests.length > 0) {
        setTests(covTests);
        setDates(reduceByDate(covTests))
      }
    }
  }, [value]);

  useEffect(() => {
    if (selectedDate) {
      setTestsByDate(tests.filter(it => dateToString(it.date) === dateToString(selectedDate)))
    } else {
      setTestsByDate([]);
    }

  }, [selectedDate, tests])

  return (
      <Box sx={{padding: theme.spacing(2)}}>
        {
          loading ?
              <Spinner/> :
              <Grid
                  container={true}
                  spacing={theme.spacing(2)}
                  direction={'row'}
              >
                <Grid item={true} md={selectedDate ? 6 : 12} xs={12}>
                  <History dates={dates} setSelectedDate={setSelectedDate} tests={testsByDate}/>
                </Grid>
                {
                  selectedDate &&
                  <Grid item={true} md={6} xs={12}>
                      <AttendanceList tests={testsByDate} date={selectedDate}/>
                  </Grid>
                }
              </Grid>
        }
        <Fab
            onClick={() => setOpen(true)}
            color={'primary'}
            aria-label={'add'}
            sx={{
              position: 'absolute',
              bottom: 16,
              right: 16,
            }}>
          <AddIcon/>
        </Fab>
        <CovTestDialog open={open} onClose={() => setOpen(false)}/>
        <Snackbar open={openError} autoHideDuration={2000} onClose={() => setOpenError(false)}>
          <Alert onClose={() => setOpenError(false)} severity={'error'} sx={{ width: '100%' }}>
            Es ist ein Fehler aufgetreten versuche es später nochmal.
          </Alert>
        </Snackbar>
      </Box>
  )
}
