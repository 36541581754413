import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Paper,
  Typography,
  useTheme
} from '@mui/material';
import { CovDate, CovTest, dateToString } from '../covTest/covTest';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { deleteCovTest } from '../firebase/firebaseService';
import { ImageDialog } from '../image/ImageDialog';

export function AttendanceList(props: { tests: CovTest[], date?: CovDate }) {
  const theme = useTheme();

  const [testsByDate, setTestsByDate] = useState<CovTest[]>(props.tests);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [covTest, setCovTest] = useState<CovTest | undefined>()

  useEffect(() => {
    setTestsByDate(props.tests)
  }, [props.tests])

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const deleteTest = async (test: CovTest) => {
    await deleteCovTest(test)
  }

  return (
      <Paper sx={{padding: theme.spacing(2)}}>
        <Typography variant={'h3'}>Anwesende vom {props.date && dateToString(props.date)}</Typography>
        {
          testsByDate.map((test) => {
            return (
                <Accordion key={test.id} expanded={expanded === test.id} onChange={handleChange(test.id)}>
                  <AccordionSummary
                      style={{backgroundColor: test.result ? 'red' : 'white'}}
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel-content"
                      id="panel-header"
                  >
                    <Typography sx={{width: '33%', flexShrink: 0}}>
                      {test.name}
                    </Typography>
                    {
                      test.tested ?
                          <CheckIcon color={'success'}/>
                          :
                          <ClearIcon color={'error'}/>
                    }
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container={true} spacing={theme.spacing(2)}>
                      <Grid item={true} xs={12}>
                        {
                          test?.image?.hasOwnProperty('url') ?
                          <img style={{width: '300px'}} src={test.image?.url} alt={test.name}/>:
                              <p>Kein Test-Nachweis hochgeladen</p>
                        }
                      </Grid>
                      <Grid item={true} xs={12}>
                        <Grid container justifyContent="flex-end" spacing={theme.spacing(2)}>
                          <Grid item={true}>
                            <Button
                                variant={'contained'}
                                color={'error'}
                                onClick={() => deleteTest(test)}
                            >
                              Löschen</Button>
                          </Grid>
                          {
                            !test?.image?.hasOwnProperty('url') &&
                            <Grid item={true}>
                                <Button
                                    onClick={() => {setCovTest(test)}}
                                    variant={'contained'}>
                                    Bild hochladen</Button>
                            </Grid>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
            )
          })
        }
        <ImageDialog test={covTest!} open={!!covTest} onClose={() => {setCovTest(undefined)}}/>
      </Paper>
  )
}
