import { CovTest, dateToString } from '../covTest/covTest';
import { getDownloadURL, ref, uploadBytes, deleteObject } from 'firebase/storage';
import { firestore, storage } from './firebase';
import { ImageEntity } from '../covTest/imageEntity';
import { addDoc, setDoc, collection, deleteDoc, doc } from 'firebase/firestore';

export async function deleteCovTest(test: CovTest): Promise<any> {
  if (test.image?.hasOwnProperty('url')) {
    return deleteImage(test.image).then(() => {
      return deleteDoc(doc(firestore, 'tests', test.id));
    })
  } else {
    return deleteDoc(doc(firestore, 'tests', test.id));
  }
}

export async function deleteImage(image: ImageEntity): Promise<any> {
  const imageRef = ref(storage, image.path);
  return deleteObject(imageRef)
}

export async function addImageToCovTest(test:CovTest, image: File): Promise<any> {
  return saveImage(image, test).then(result => {
    return updateTest(result).then(() => {
      return Promise.resolve();
    })
  })
}

export async function saveCovTest(test: CovTest, image?: File): Promise<any> {
  if (image) {
    return saveImage(image, test).then(result => {
      return saveTest(result).then(() => {
            return Promise.resolve();
          }
      )
    });
  } else {
    return saveTest(test)
  }
}

function updateTest(test: CovTest): Promise<any> {
  return setDoc(doc(firestore, 'tests', test.id), {
    name: test.name,
    tested: test.tested,
    result: test.result,
    date: {...test.date},
    image: {...test.image}
  });
}

function saveTest(test: CovTest): Promise<any> {
  return addDoc(collection(firestore, 'tests'), {
    name: test.name,
    tested: test.tested,
    result: test.result,
    date: {...test.date},
    image: test.image ? {...test.image}: null
  });
}

async function saveImage(image: File, test: CovTest): Promise<CovTest> {
  const img = new File([image], encodeUri(test.name), {type: image.type});
  const path = `images/${encodeUri(dateToString(test.date))}/${encodeUri(test.name)}`

  const imageRef = ref(storage, path)

  await uploadBytes(imageRef, img);
  const downloadUrl = await getDownloadURL(imageRef);
  const imageEntity: ImageEntity = {url: downloadUrl, path: path}
  return {...test, image: imageEntity}
}


function encodeUri(str: string) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16);
  });
}
