import React, { useState } from 'react';
import { CovTest, EMPTY_COV_TEST } from './covTest';
import { saveCovTest } from '../firebase/firebaseService';
import { Box, Button } from '@mui/material';
import { Spinner } from '../spinner/Spinner';
import { CovTestForm } from './CovTestForm';

export function CovTestPage() {
  const [valid, setValid] = useState(false);
  const [test, setTest] = useState<CovTest | undefined>(EMPTY_COV_TEST);
  const [image, setImage] = useState<File | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const save = async () => {
    if (test) {
      setLoading(true);
      await saveCovTest(test, image)
      setLoading(false);
    }
  }

  return (
      <Box sx={{padding: theme => theme.spacing(2)}}>
        {
          loading ?
              <Spinner/> :
              <CovTestForm onChange={(value, image) => {
                setTest(value)
                setImage(image)
                setValid(!!value)
              }}/>
        }
        <Button disabled={!valid} variant={'contained'} onClick={save}>Speichern</Button>
      </Box>
  )
}
