import React, { useEffect, useState } from 'react';
import {
  IconButton,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
  useTheme
} from '@mui/material';
import { CovDate, CovTest, dateToString } from '../covTest/covTest';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteCovTest } from '../firebase/firebaseService';


export function History(props: { dates: CovDate[], tests: CovTest[], setSelectedDate: (date?: CovDate) => void }) {
  const theme = useTheme();
  const [dates, setDates] = useState<CovDate[]>(props.dates);

  useEffect(() => {
    setDates(props.dates)
  }, [props.dates]);

  const deleteDate = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    for (const it of props.tests) {
      await deleteCovTest(it);
    }
    props.setSelectedDate(undefined);
  }

  const listTile = (date: CovDate, index: number) => {
    return (
        <ListItemButton key={index} onClick={() => props.setSelectedDate(date)}>
          <ListItemText primary={dateToString(date)}/>
          <ListItemSecondaryAction>
            <IconButton
                aria-label={'delete'}
                onClick={deleteDate}
            >
              <DeleteIcon/>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItemButton>
    )
  }

  return (
      <Paper sx={{padding: theme.spacing(2)}}>
        <Typography variant={'h3'}>Datum</Typography>
        <List>
          {
            dates.map((date, index) => {
              return listTile(date, index)
            })
          }
        </List>
      </Paper>
  )
}
