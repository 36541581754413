import { ImageEntity } from './imageEntity';

export interface CovTest {
  id: string;
  name: string;
  date: CovDate;
  tested: boolean;
  result: boolean;
  image?: ImageEntity
}

export interface CovDate {
  year: number;
  month: number;
  day: number;
}

export function dateToString(date?: CovDate) {
  if (date) {
    return `${date.day}.${date.month}.${date.year}`
  }
  return ''
}

export const EMPTY_COV_TEST = {
  id: '',
  name: '',
  tested: true,
  result: false,
  date: {year: -1, month: -1, day: -1},
  image: undefined
}

export function snapshotToCovTest(snapshot: any) {
  return {
    id: snapshot.id,
    name: snapshot.name,
    tested: snapshot.tested,
    result: snapshot.result,
    date: snapshot.date,
    image: snapshot.image
  } as CovTest
}
