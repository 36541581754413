import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { FirebaseApp } from '@firebase/app';
import { enableIndexedDbPersistence, getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import { getAuth, signInAnonymously} from 'firebase/auth';

export const APP = initFirebase();
export const storage = initStorage();
export const firestore = initFireStore();
export const AUTH = getAuth(APP);

function initFirebase(): FirebaseApp {
  return  firebase.initializeApp({
    apiKey: 'AIzaSyDUamN6ccYudUiH_xTiZ6I0GT327egLC6Q',
    authDomain: 'cov-documentation.firebaseapp.com',
    projectId: 'cov-documentation',
    storageBucket: 'cov-documentation.appspot.com',
    messagingSenderId: '860652572640',
    appId: '1:860652572640:web:a7abcc84fe458aec0c2585'
  });
}

function initStorage() {
 return getStorage(APP);
}

function initFireStore() {
  let _firestore = getFirestore(APP);

  enableIndexedDbPersistence(_firestore).catch((err) => {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  })
  return _firestore
}

export function loginAnonymously(): Promise<any> {
  return signInAnonymously(AUTH)
}
