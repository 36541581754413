import { useCallback, useEffect, useMemo, useState } from 'react';
import { KdUser } from './useSignInWithGoogle';
import { useAuthState } from 'react-firebase-hooks/auth';
import { AUTH, firestore } from './firebase';
import { collection, getDocs } from 'firebase/firestore';

// eslint-disable-next-line import/no-anonymous-default-export
export default (
) => {
  const [
    loggedInUser,
    setLoggedInUser,
  ] = useState<KdUser>();
  const [user, l, e] = useAuthState(AUTH);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const loadUser = useCallback(async () => {
    setLoading(true);
    const adminSnapshots = await getDocs(collection(firestore, 'admins'));
    const admins = adminSnapshots.docs.map(it => it.data().mail);
    await user?.getIdTokenResult().then(result => {
      const admin = admins.includes(user.email);
      setLoggedInUser({
        imageUrl: result.claims.picture,
        admin: admin,
        name: user.displayName,
        email: user.email
      } as KdUser);
    });
    setLoading(false);

  }, [user])

  useEffect(() => {
    loadUser()
  }, [user, loadUser]);

  useEffect(() => {
    setLoading(l);
  }, [l]);

  useEffect(() => {
    setError(e);
  }, [e]);

  return useMemo(() => [
    loggedInUser,
    loading,
    error
  ], [loggedInUser, loading, error]);
}
