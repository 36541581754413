import React, { useCallback, useEffect, useState } from 'react';
import { Box, FormControlLabel, Grid, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import deLocale from 'date-fns/locale/de';
import { CovTest, EMPTY_COV_TEST } from './covTest';
import { CovDropzone } from '../dropzone/CovDropzone';
import { NameInput } from './NameInput';

export function CovTestForm(props: { onChange: (test?: CovTest, image?: File) => void }) {
  const {onChange} = props;
  const [test, setTest] = useState({...EMPTY_COV_TEST, date:
        {day: new Date().getDate(), month: new Date().getMonth() + 1, year: new Date().getFullYear()}
  })
  const [date, setDate] = useState<Date | null>(new  Date());
  const [initial, setInitial] = useState(true);
  const [image, setImage] = useState<File | undefined>(undefined);

  const [nameError, setNameError] = useState(false)
  const [dateError, setDateError] = useState(false)

  const isValid = useCallback(() => {
    if (!initial) {
      setNameError(test.name?.length === 0)
      const covDate = test.date;
      setDateError(
          covDate.day === -1 ||
          covDate.month === -1 ||
          covDate.year === -1)

      return !nameError && !dateError
    }

    return false;
  }, [dateError, nameError, test, initial])

  useEffect(() => {
    if (isValid()) {

      onChange(test, image);
    } else {
      onChange(undefined)
    }

  }, [test, onChange, isValid, image]);

  const result = () => {
    return (
        <ToggleButtonGroup
            sx={{margin: theme => theme.spacing(1)}}
            value={test.result}
            exclusive={true}
            onChange={(event: React.MouseEvent<HTMLElement>,
                       value: boolean | null,) => {
              setInitial(false)
              setTest(prevState => {
                return ({...prevState, result: value || false})
              })
            }}
            aria-label={'test result'}
        >
          <ToggleButton value={false} aria-label={'negative'}>
            negativ
          </ToggleButton>
          <ToggleButton value={true} aria-label={'positive'}>
            positiv
          </ToggleButton>
        </ToggleButtonGroup>
    )
  }

  return (
      <Grid container={true} direction={'column'}>
        <Grid item={true}>
          <NameInput error={nameError} onChange={(name) => {
            setInitial(false)
            setTest(prevState => {
              return ({...prevState, name: name})
            })
          }}/>
        </Grid>
        <Grid item={true}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
            <DatePicker
                mask={'__.__.____'}
                label={'Datum'}
                value={date}
                onChange={(newValue) => {
                  setInitial(false)
                  if (newValue) {
                    setTest(prevState => {
                      return ({
                        ...prevState,
                        date: {day: newValue.getDate(), month: newValue.getMonth() + 1, year: newValue.getFullYear()}
                      })
                    })
                  } else {
                    setTest(prevState => {
                      return ({
                        ...prevState,
                        date: {day: -1, month: -1, year: -1}
                      })
                    })
                  }
                  setDate(newValue);
                }}
                renderInput={(params) =>
                    <TextField
                        required={true}
                        error={dateError}
                        helperText={dateError && "Datum muss gewählt sein"}
                        fullWidth={true}
                        variant={'standard'}
                        {...params}
                    />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item={true}>
          <FormControlLabel
              control={result()}
              label={'Testergebnis'}
              labelPlacement={'start'}
          />
        </Grid>
        <Grid item={true}>
          {
            image ?
                <Box>
                  <img alt={image.name} style={{width: '300px'}} src={URL.createObjectURL(image)}/>
                </Box> :
                <CovDropzone onImageAdded={(image) => setImage(image)}/>
          }
        </Grid>
      </Grid>
  )
}
