import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useKdUser from '../firebase/useKdUser';
import { Button } from '@mui/material';
import useSignInWithGoogle from '../firebase/useSignInWithGoogle';
import { AUTH } from '../firebase/firebase';

export function KdAppBar() {
  const [user, ,] = useKdUser();
  const [login, , , ] = useSignInWithGoogle(AUTH);


  return (
      <Box sx={{flexGrow: 1}}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
              KD Covid Testdokumentation
            </Typography>
            {
              !(user && user.admin) &&
              <Button color="inherit" onClick={() => login()}>Login</Button>
            }
          </Toolbar>
        </AppBar>
      </Box>
  );
}
