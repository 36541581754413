import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { CovTest } from '../covTest/covTest';
import { Spinner } from '../spinner/Spinner';
import { CovDropzone } from '../dropzone/CovDropzone';
import { addImageToCovTest } from '../firebase/firebaseService';

export function ImageDialog(props: { open: boolean, onClose: VoidFunction, test: CovTest }) {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<File | undefined>(undefined);
  const [test, setTest] = useState<CovTest>(props.test);

  useEffect(() => {
    setTest(props.test)
  }, [props.test])

  const save = async () => {
    if (image) {
      setLoading(true);
      await addImageToCovTest(test, image)
      setLoading(false);
      setImage(undefined);
      props.onClose();
    }
  }

  return (
      <Dialog
          fullWidth={true}
          open={props.open}
          onClose={props.onClose}>
        <DialogTitle>Upload Test-Nachweis</DialogTitle>
        <DialogContent>
          {
            loading ?
                <Spinner/> :
                <>
                  {
                    image ?
                        <img alt={image.name} style={{width: '300px'}} src={URL.createObjectURL(image)}/> :
                        <CovDropzone onImageAdded={(img) => setImage(img)}/>
                  }
                </>
          }
        </DialogContent>
        <DialogActions>
          <Button color={'inherit'} onClick={props.onClose}>Abbrechen</Button>
          <Button disabled={!image} onClick={save}>Speichern</Button>
        </DialogActions>
      </Dialog>
  )
}
