import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material';

const Container = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: '2px',
  borderRadius: '2px',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
})

export function CovDropzone(props: {onImageAdded: (image: File) => void}) {
  const {onImageAdded} = props;
  const {
    getRootProps,
    getInputProps,
    acceptedFiles
  } = useDropzone({accept: 'image/*', maxFiles: 1});

  useEffect(() => {
    if (acceptedFiles.length === 1) {
      onImageAdded(acceptedFiles[0])
    }
  }, [acceptedFiles, onImageAdded])

  return (
      <div className="container">
        <Container {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop den Test, oder klicke hier</p>
        </Container>
      </div>
  );
}
