import React, { useEffect, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { collection } from 'firebase/firestore';
import { firestore } from '../firebase/firebase';
import { Autocomplete, CircularProgress, createFilterOptions, TextField } from '@mui/material';

interface SlackUser {
  id: string,
  name: string,
  slackId: string,
  inputValue?: string
}

const filterName = createFilterOptions<SlackUser>();

export function NameInput(props: {onChange: (name: string) => void, error?: boolean}) {
  const {onChange, error} = props
  const [selectedValue, setSelectedValue] = useState<SlackUser | null>(null);
  const [users, setUsers] = useState<SlackUser[]>([])

  const [value, loading, ] = useCollectionData(
      collection(firestore, 'slackUser'),
      {idField: 'id'},
  );

  useEffect(() => {
    if (value) {
      const slackUsers = value.map(it => {
        return {
          id: it.id,
          slackId: it.slackId,
          name: it.name
        } as SlackUser
      })

      if (slackUsers && slackUsers.length > 0 ) {
        setUsers(slackUsers)
      }
    }
  }, [value])

  return (
      <Autocomplete
        value={selectedValue}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setSelectedValue({id: '', name: newValue, slackId: ''})
            onChange(newValue)
          } else if (newValue && newValue.inputValue) {
            setSelectedValue({id: '', name: newValue.inputValue, slackId: ''})
            onChange(newValue.inputValue)
          } else {
            setSelectedValue(newValue)
            onChange(newValue?.name || '')
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filterName(options, params);

          const {inputValue} = params;
          const isExisting = options.some((option) => inputValue === option.name);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              id: '',
              slackId: '',
              inputValue,
              name: `${inputValue} hinzufügen`
            });
          }

          return filtered;
        }}
        selectOnFocus={true}
        clearOnBlur={true}
        handleHomeEndKeys={true}
        id={'name'}
        options={users}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }

          if (option.inputValue) {
            return option.inputValue;
          }

          return option.name;
        }}
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        fullWidth={true}
        freeSolo={true}
        loading={loading}
        renderInput={(params) => (
            <TextField
                helperText={error && "Name muss befüllt sein"}
                error={error}
                autoFocus={true}
                required={true}
                variant={'standard'}
                {...params}
                label={'Name'}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color={'inherit'} size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                  ),
                }}
            />
        )}
        />
  )
}
