import React, { useEffect, useState } from 'react';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import { KdAppBar } from './appBar/KdAppBar';
import { green } from '@mui/material/colors';
import { Spinner } from './spinner/Spinner';
import { loginAnonymously } from './firebase/firebase';
import { RestrictedArea } from './restrictedArea/RestrictedArea';


const theme = createTheme({
  palette: {
    primary: {
      main: '#C3D451',
    },
    secondary: {
      main: green[500],
    },
  },
});

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loginAnonymously().then(() => {
      setLoading(false)
    })
  }, [])

  return (
      <ThemeProvider theme={theme}>
        <Box>
          <KdAppBar/>

          {
            loading ?
                <Spinner/> :
                <RestrictedArea/>

          }
        </Box>
      </ThemeProvider>
  );
}

export default App;
