import React from 'react';
import useKdUser from '../firebase/useKdUser';
import { Box } from '@mui/material';
import { Dashboard } from '../dashboard/Dashboard';
import { CovTestPage } from '../covTest/CovTestPage';

export function RestrictedArea() {

  const [user, , ] = useKdUser();

  return (
      <Box>
        {
          (user && user.admin) ?
              <Dashboard/> :
              <CovTestPage/>
        }
      </Box>

  )
}
